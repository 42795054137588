<template>
  <div class="FundMent">
    <div class="user-data-container">
      <div class="user-data-item">
        <el-avatar :size="108" :src="UserInfo.avatarImg"></el-avatar>
      </div>
      <div class="user-data-item" style="margin-left: 30px;">
        <div class="user-data-item-label username">{{ UserInfo.nickName }}</div>
        <div class="user-data-item-content">
          <div class="content-total">总资产</div>
          <div class="content-symbol">￥</div>
          <div class="content-number">{{ UserInfo.totalAssets || 0 }}</div>
        </div>
      </div>
      <div class="user-data-item" style="margin-left: 60px;">
        <div class="user-data-item-label">可提现余额</div>
        <div class="user-data-item-content">
          <div class="content-symbol">￥</div>
          <div class="content-number">0.00</div>
        </div>
      </div>
      <div class="user-data-item divider">
      </div>
      <div class="user-data-item">
        <div class="user-data-item-label">
          不可提现余额
          <el-tooltip placement="top" effect="light" popper-class="tips">
            <div slot="content">不可用包括正在提现、正在结算、 <br />投诉冻结和保障押金等金额。</div>
            <i class="el-icon-question" />
          </el-tooltip>
        </div>
        <div class="user-data-item-content">
          <div class="content-symbol">￥</div>
          <div class="content-number">0.00</div>
        </div>
      </div>
      <div class="user-data-item" style="margin-left: 60px;">
        <button class="user-data-button" v-show="Recharge">充值</button>
      </div>
      <div class="user-data-item" style="margin-left: 30px;">
        <button class="user-data-button blue" v-show="Withdrawal">提现</button>
      </div>
    </div>
    <!-- 列表 -->
    <div class="tabs-container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="财务明细" name="财务明细">
          <div class="List">
            <div class="Title">
              <div class="operate">
                <div class="date_check">
                  <span class="name">订单时间：</span>
                  <el-date-picker
                      v-model="queryDate"
                      size="mini"
                      style="height: 41px"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                  ></el-date-picker>
                  <div class="date_check">
                    <span class="name names">订单编号：</span>
                    <el-input placeholder="请输入订单编号" v-model="queryOrder"></el-input>
                  </div>
                  <el-button type="danger" size="mini" round plain style="margin-left: 20px"
                             @click="getFundList"
                  >查询
                  </el-button
                  >
                </div>
              </div>
            </div>
            <div class="list">
              <div class="list-head">
                <div class="liushui">流水号</div>
                <div class="order">订单编号</div>
                <div class="day">时间</div>
                <div class="type">类型</div>
                <div class="desc">描述</div>
                <div class="price">金额</div>
              </div>
              <div class="lists">
                <div class="item" v-for="item in assetsList" :key="item.id">
                  <div class="liushui">{{ item.id }}</div>
                  <div class="order">{{ item.orderNo }}</div>
                  <div class="day">{{ formatDate(item.createTime) }}</div>
                  <div class="type" :class="item.typeClass"> {{ item.type }}</div>
                  <div class="desc">{{ item.remark }}</div>
                  <div class="price">￥{{ item.assets }}</div>
                </div>
              </div>
              <Pagination :total="assetsPage.total" :size="assetsPage.pageSize" :page="assetsPage.pageNo"
                          @handlePage="assetsPageChange" v-if="assetsPage.total > assetsPage.pageSize" />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="消费记录" name="消费记录">消费记录</el-tab-pane>
        <el-tab-pane label="佣金记录" name="佣金记录">佣金记录</el-tab-pane>
        <el-tab-pane label="提现记录" name="提现记录">提现记录</el-tab-pane>
        <el-tab-pane label="充值记录" name="充值记录">充值记录</el-tab-pane>
      </el-tabs>
    </div>
  
  
  </div>
</template>
<script>
import Pagination from '@/components/Pagination/index.vue'
import { ASSETS_TYPE } from '@/constants/assets'

export default {
  components: { Pagination },
  data() {
    return {
      UserInfo:   '',
      FundList:   '',
      Recharge:   null,
      Withdrawal: null,
      queryDate:  '',
      queryOrder: '',
      activeName: '财务明细',
      
      assetsPage: {
        total:    0,
        pageNo:   1,
        pageSize: 10,
        
      }
    }
  },
  created() {
    this.getUserInfo()
    this.getFundList()
  },
  computed: {
    assetsList() {
      if (!this.FundList || !this.FundList.length) return []
      this.FundList.forEach(item => {
        let key = Object.keys(ASSETS_TYPE).find(key => ASSETS_TYPE[key].value === item.assetsOper)
        if (key) {
          item.type = ASSETS_TYPE[key].label
          item.typeClass = ASSETS_TYPE[key].cssClass
        }
      })
      return this.FundList
    }
  },
  methods:  {
    getUserInfo() {
      let url = `/app-api/hiring/user/get?id=${this.$store.getters.getUser.id}`
      // TODO 字段确定后放开
      //if (this.queryOrder) url += `&order=${this.queryOrder}`
      //if (this.queryDate) url += `&startDate=${this.queryDate[0]}&endDate=${this.queryDate[1]}`
      this.$axios
          .get(url)
          .then((res) => {
            // console.log(res.data.data.service_status);
            if (res.data.data.serviceStatus === 0) {
              this.Recharge = true
            } else if (res.data.data.serviceStatus === 1) {
              this.Recharge = true
              this.Withdrawal = true
            }
            if (res.data.code === 0) {
              this.UserInfo = res.data.data
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    getFundList() {
      let url = '/app-api/hiring/total-assets-list/page'
      this.$axios
          .get(url, {
            params: {
              userId:   this.$store.getters.getUser.id,
              pageNo:   this.assetsPage.pageNo,
              pageSize: this.assetsPage.pageSize
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.FundList = res.data.data.list
              this.assetsPage.total = res.data.data.total
            }
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    handleClick() {
    
    },
    assetsPageChange(pageNo) {
      this.assetsPage.pageNo = pageNo
      this.getFundList()
    }
  },
}
</script>

<style lang="less">
@main-color: #FC3737;

.el-tooltip__popper.is-light[x-placement^=top] .popper__arrow {
  border-top-color: #D9D9D9;
}

.el-tooltip__popper.is-light.tips {
  border: 1px solid #D9D9D9;
}

.el-tabs__active-bar {
  background-color: @main-color;
}

.el-tabs__item.is-active,
.el-tabs__item:hover {
  color: @main-color;
}

</style>
<style lang="less" scoped>
@main-color: #FC3737;
.tabs-container {
  padding: 20px 30px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 4px 1px rgba(204, 226, 237, 0.5);
}

.user-data-container {
  display: flex;
  align-items: center;
  padding: 30px 50px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 4px 4px 1px rgba(204, 226, 237, 0.5);
  border-radius: 8px;
  
  .user-data-item {
    display: flex;
    flex-direction: column;
    
    & + .user-data-item {
      margin-left: 40px;
    }
    
    &.divider {
      background-color: #D9D9D9;
      width: 1px;
      height: 40px;
    }
    
    .user-data-item-label {
      color: #000000;
      font-size: 16px;
      
      &.username {
        font-size: 22px;
        font-weight: 600;
      }
      
      
      .el-icon-question {
        color: @main-color;
        cursor: pointer;
        font-size: 18px;
      }
    }
    
    .user-data-item-content {
      color: @main-color;
      display: flex;
      align-items: baseline;
      font-size: 14px;
      margin-top: 10px;
      
      .content-total {
        color: #999999;
      }
      
      .content-symbol {
        font-size: 12px;
      }
      
      .content-number {
        font-size: 16px;
      }
    }
    
    
  }
  
  
}

.user-data-button {
  color: #ffffff;
  width: 68px;
  height: 32px;
  border-radius: 18px;
  background-color: @main-color;
  cursor: pointer;
  
  &.blue {
    background-color: #3D8BFF;
  }
  
  &:hover {
    filter: brightness(0.95);
  }
}


.List {
  background: #ffffff;
  box-sizing: border-box;
  
  /deep/ .el-range-editor--mini .el-range-separator {
    line-height: 30px;
  }
  
  /deep/ .el-range-editor--mini .el-range__icon {
    font-size: 22px;
    margin-top: 4px;
  }
  
  .Title {
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    box-sizing: border-box;
    
    .title {
      font-weight: 700;
      color: #000000;
      font-size: 28px;
    }
    
    .operate {
      display: flex;
      align-items: center;
      
      .date_check {
        display: flex;
        align-items: center;
        
        .name {
          color: #000000;
          line-height: 23px;
          font-size: 20px;
          font-weight: 700;
        }
        
        .names {
          width: 165px;
          padding-left: 30px;
        }
      }
      
      .dropdown {
        margin-right: 16px;
        width: 136px;
        height: 42px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #efefef;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
      }
    }
  }
  
  .list {
    .liushui {
      width: 80px;
    }
    
    .order {
      width: 220px;
    }
    
    .day {
      width: 150px;
    }
    
    .type {
      width: 120px;
      text-align: center;
      
      &.info {
        color: #999999;
      }
      
      &.success {
        color: #209F85;
      }
      
      &.warn {
        color: #ee914e;
      }
      
      &.danger {
        color: #FF3D3D;
      }
    }
    
    .desc {
      width: 240px;
    }
    
    .price {
      width: 140px;
    }
    
    .list-head {
      height: 45px;
      background: #f1f1f1;
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 28px;
      box-sizing: border-box;
      margin-top: 30px;
    }
    
    .item {
      display: flex;
      padding-left: 28px;
      box-sizing: border-box;
      height: 61px;
      align-items: center;
      
      .day {
        font-size: 14px;
        color: #999999;
      }
      
      .price {
        color: #fc3737;
      }
    }
  }
}
</style>
